// Datepicker

$.validate({
    lang: 'de',
    modules : 'file, location, date'
});

$('.datepicker').datepicker({
    format: "dd.mm.yyyy",
    todayBtn: "linked",
    language: "de",
    autoclose: true
});